import * as React from "react";

import { CareerPageComponent } from "../components/CareerPageComponent/CareerPageComponent";
import { Layout } from "../components/Layout/Layout";
import Seo from "../components/seo";

const IndexPage = () => (
    <Layout>
        <Seo title="Career" />
        <CareerPageComponent />
    </Layout>
);

export default IndexPage;
