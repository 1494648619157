import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { Footer } from "../Footer/Footer";
import { Star } from "../Star/Star";
import { Decorates } from "../Decorates/Decorates";

import { StyledBorder, StyledPageWrapper, StyledTitle, StyledText, StyledDecoratesWrapper, StyledHeaderWrapper } from "../IndexPageComponent/style";

import { StyledCustomerSupport, StyledButtonWrapper } from "../SupportPageComponent/style";

import { StyledCareerInfo, StyledConnectToTeam, StyledTeamInfo, StyledList, StyledListElement } from "./style";

const firstGameStyle = {
    position: "absolute",
    top: "-416px",
    left: "-511px",
    opacity: "0.4",
    transform: "rotate(-62deg) rotateY(180deg)"
};

const secondGameStyle = {
    width: "850px",
    position: "absolute",
    top: "-388px",
    left: "923px",
    opacity: ".4",
    transform: "rotateY(180deg) rotate(-300deg) scale(0.8)"
};

const owlStyle = {
    position: "absolute",
    bottom: "110px",
    left: "-70px",
    opacity: ".4",
    transform: "scale(0.8)"
};

export const CareerPageComponent = () => {
    const professions = ["developers", "artists", "motion designers", "administrators"];

    return (
        <StyledPageWrapper>
            <StyledHeaderWrapper>
                <StaticImage src="../../images/carreer_header.png" width={300} placeholder="blurred" alt="carreer header" />
            </StyledHeaderWrapper>
            <StyledBorder>
                <StyledDecoratesWrapper>
                    <Decorates firstGameStyle={firstGameStyle} secondGameStyle={secondGameStyle} owlStyle={owlStyle} />
                    <StyledCustomerSupport>
                        <StaticImage src="../../images/man_2.png" alt="man" placeholder="blurred" />
                        <StyledCareerInfo>
                            <StyledTitle>
                                <Star />
                                <StaticImage src="../../images/logo_may-play.png" width={240} placeholder="blurred" alt="logo may play" />
                                <Star />
                            </StyledTitle>
                            <StyledText>
                                Members of our team are the one of the most skillfull specialist in the game design sphere. And we always in seach for the new talents
                            </StyledText>
                            <Link to="/contacts">
                                <StyledButtonWrapper>
                                    <StaticImage src="../../images/join_btn.png" alt="contact btn" placeholder="blurred" />
                                </StyledButtonWrapper>
                            </Link>
                        </StyledCareerInfo>
                    </StyledCustomerSupport>
                    <StyledConnectToTeam>
                        <StyledTeamInfo>
                            <StyledText>If you want to become the part of our team and make a career in one of the most rapidly developing IT sectors - join us!</StyledText>
                            <Link to="/contacts">
                                <StaticImage src="../../images/join_btn.png" alt="contact btn" placeholder="blurred" />
                            </Link>
                            <StyledList>
                                {professions.map((profession, index) => (
                                    <StyledListElement key={index}>
                                        <StyledText>{profession}</StyledText>
                                        <Star />
                                    </StyledListElement>
                                ))}
                            </StyledList>
                        </StyledTeamInfo>
                        <StaticImage src="../../images/working_women.png" alt="working women" width={280} placeholder="blurred" />
                    </StyledConnectToTeam>
                    <Footer />
                </StyledDecoratesWrapper>
            </StyledBorder>
        </StyledPageWrapper>
    );
};
